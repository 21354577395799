<template>
  <div>
    <div class="d-flex align-items-center p-3">
      <select class="form-control select-category mr-2">
        <option v-for="i in categories" :key="i">{{ i }}</option>
      </select>

      <button class="p-2 btn" v-b-modal.modal-category>
        <img src="./images/icon-filter.svg" />
      </button>

      <b-modal
        id="modal-category"
        title-class="modal-category-title"
        title="商品分類"
        centered
      >
        <template #modal-header="{ close }">
          <h5 class="mb-0 w-100 text-center">商品分類</h5>
          <b-button size="sm" variant="link" class="close" @click="close()">
            <img src="./images/icon-close.svg" />
          </b-button>
        </template>

        <div class="row filters">
          <div
            class="col-6 mb-2"
            v-for="i in categories"
            :key="i"
            :style="colorObject"
          >
            <button class="btn btn-light rounded-pill w-100 p-2 filter">
              {{ i }}
            </button>
          </div>
        </div>

        <template #modal-footer="{ ok, cancel }">
          <div class="row w-100" :style="colorObject">
            <div class="col">
              <b-button
                size="lg"
                variant="outline-dark"
                class="w-100"
                @click="cancel()"
              >
                取消
              </b-button>
            </div>
            <div class="col">
              <b-button size="lg" variant="primary" class="w-100" @click="ok()">
                確認
              </b-button>
            </div>
          </div>
        </template>
      </b-modal>

      <button
        class="p-2 btn"
        @click="
          currentView =
            gridViews[(gridViews.indexOf(currentView) + 1) % gridViews.length]
        "
      >
        <img
          v-if="currentView == gridViews[0]"
          src="./images/icon-grid-2.svg"
        />
        <img
          v-if="currentView == gridViews[1]"
          src="./images/icon-grid-1.svg"
        />
        <img
          v-if="currentView == gridViews[2]"
          src="./images/icon-grid-list.svg"
        />
      </button>
    </div>
    <div class="products px-3 row" :class="currentView">
      <div class="col mb-3" v-for="p in products" :key="p.name">
        <router-link
          class="products__item"
          role="button"
          :to="{ name: 'LiffShopProduct', params: { id: p.title } }"
        >
          <div class="products__item__photo product__photo">
            <img
              :src="`https://picsum.photos/200?${Math.random()}`"
              class="img-fluid"
            />
          </div>
          <div class="products__item__title product__title">{{ p.title }}</div>
          <div class="products__item__brand">{{ p.brand }}</div>

          <div class="products__item__price">
            <div>
              <div class="oprice">{{ p.oprice }}</div>
              <div class="price">{{ p.price }}</div>
            </div>
            <button class="btn bag">
              <img src="./images/icon-shopping-bag.svg" />
            </button>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import themeColor from "@/mixins/liff/themeColor";

const products = [
  {
    title: "Eco-Friendly Shopping Bag | CHAMOMILE - SIZE S",
    brand: "yaambrand",
    price: "NT$ 375",
  },
  {
    title: "Rex│銀色反光偏光鏡片│墨鏡│太陽眼鏡",
    brand: "墨鏡人生",
    oprice: "NT$ 1,980",
    price: "NT$ 375",
  },
  {
    title: "2022 | 虎虎生風 | 貓日曆",
    brand: "Hank Max Studio",
    oprice: "NT$ 980",
    price: "NT$ 690",
  },
  {
    title: "日本Toffy Drip Coffee Maker 咖啡機 板岩綠",
    brand: "Toffy",
    price: "NT$ 2,180",
  },
  {
    title: "Eco-Friendly Shopping Bag | CHAMOMILE - SIZE S",
    brand: "yaambrand",
    price: "NT$ 375",
  },
  {
    title: "Rex│銀色反光偏光鏡片│墨鏡│太陽眼鏡",
    brand: "墨鏡人生",
    oprice: "1,980",
    price: "NT$ 375",
  },
  {
    title: "2022 | 虎虎生風 | 貓日曆",
    brand: "Hank Max Studio",
    oprice: "NT$ 980",
    price: "NT$ 690",
  },
  {
    title: "日本Toffy Drip Coffee Maker 咖啡機 板岩綠",
    brand: "Toffy",
    price: "NT$ 2,180",
  },
  {
    title: "Eco-Friendly Shopping Bag | CHAMOMILE - SIZE S",
    brand: "yaambrand",
    price: "NT$ 375",
  },
  {
    title:
      "Rex│銀色反光偏光鏡片│墨鏡│太陽眼鏡Rex│銀色反光偏光鏡片│墨鏡│太陽眼鏡Rex│銀色反光偏光鏡片│墨鏡│太陽眼鏡",
    brand: "墨鏡人生",
    oprice: "NT$ 1,980",
    price: "NT$ 375",
  },
  {
    title: "2022 | 虎虎生風 | 貓日曆",
    brand: "Hank Max Studio",
    oprice: "NT$ 980",
    price: "NT$ 690",
  },
  {
    title: "日本Toffy Drip Coffee Maker 咖啡機 板岩綠",
    brand: "Toffy",
    price: "NT$ 2,180",
  },
];

const gridViews = ["grid-list", "grid-2", "grid-1"];

const categories = [
  "全部",
  "精品",
  "女性",
  "男性",
  "包包配件",
  "居家生活",
  "科技創意",
  "品味美食",
];

export default {
  mixins: [themeColor],
  data() {
    return {
      products,
      gridViews,
      categories,
      currentView: "grid-list",
    };
  },
};
</script>

<style lang="scss" scoped>
.select-category {
  border-radius: 0.5rem;
  border: 0;
  background-color: rgba(236, 239, 241, 0.5);
  font-family: "Noto Sans TC", "Helvetica";
  font-size: 14px;
  font-weight: 500;
}

.products {
  &__item {
    color: #212121;
    text-decoration: none !important;
    display: grid;

    &__photo {
      grid-area: photo;
    }

    &__title {
      grid-area: title;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
    }
    &__brand {
      grid-area: brand;
      font-size: 14px;
      font-weight: 400;
      color: #616161;
    }

    &__price {
      grid-area: price;
      display: flex;
      justify-content: space-between;
      align-items: end;
    }

    .bag {
      padding: 0.8rem;
      border-radius: 0.5rem;
    }
  }

  &.grid-list {
    .col {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .products__item {
      grid-template-columns: 30% auto;
      grid-template-rows: min-content min-content max-content;
      gap: 0px 1rem;
      grid-template-areas:
        "photo title"
        "photo brand"
        "photo price";
    }
  }

  &.grid-1 {
    .col {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .products__item {
      grid-template-columns: auto 30%;
      grid-template-rows: min-content min-content max-content;
      gap: 0px;
      grid-template-areas:
        "photo photo"
        "title price"
        "brand price";

      &__photo {
        margin-bottom: 0.5rem;
      }
      &__price {
        position: relative;
        align-items: start;
        justify-content: end;
        font-size: 18px;
      }

      .bag {
        position: absolute;
        top: -4rem;
        right: 0.8rem;
        background-color: rgba(33, 33, 33, 0.5);
        img {
          filter: brightness(10);
        }
      }
    }
  }

  &.grid-2 {
    .col {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .products__item {
      height: 100%;
      grid-template-columns: 100%;
      grid-template-rows: min-content min-content min-content auto;
      gap: 0px;
      grid-template-areas:
        "photo"
        "title"
        "brand"
        "price";

      &__photo {
        margin-bottom: 0.5rem;
      }
    }
  }
}

.filters {
  .filter {
    position: relative;
    background-color: #f5f7f8;
    border-color: #f5f7f8;
    font-size: 14px;
    font-weight: 500;
    &:focus,
    &:active,
    &.active {
      background-color: #90a4ae;
      border-color: #90a4ae;
      color: white;
      &::after {
        content: url("./images/icon-check.svg");
        position: absolute;
        right: 10px;
        fill: black;
      }
    }
  }
}
</style>
